import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  background-color: #1f2b3a;
  color: white;
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
  margin-bottom: 50px;  /* Ajouter un espace en bas */
  border-radius: 8px;
`;

const Title = styled.h1`
  text-align: center;
  margin-bottom: 20px;
`;

const SubTitle = styled.h2`
  margin-top: 20px;
  margin-bottom: 10px;
`;

const Text = styled.p`
  margin-bottom: 10px;
  line-height: 1.6;
`;

const List = styled.ul`
  margin-left: 20px;
  margin-bottom: 10px;
  line-height: 1.6;
`;

const ListItem = styled.li`
  margin-bottom: 5px;
`;

const PrivacyPolicy = () => {
    return (
        <Container>
            <Title>Politique de confidentialité de X'Plore</Title>
            <Text>
                X'Plore s'engage à protéger votre vie privée et à assurer la sécurité de vos données personnelles. Cette politique de confidentialité explique comment nous collectons, utilisons et partageons vos informations lorsque vous utilisez notre application.
            </Text>
            <SubTitle>1. Informations que nous collectons</SubTitle>
            <List>
                <ListItem><strong>Informations de compte:</strong> Lorsque vous créez un compte X'Plore, nous collectons votre adresse e-mail ou les informations de votre compte Google nécessaires pour vous identifier et vous permettre d'accéder à nos services.</ListItem>
                <ListItem><strong>Données de jeu:</strong> Nous collectons des données sur votre progression dans le jeu, vos scores et vos interactions avec d'autres joueurs. Ces données nous aident à améliorer l'expérience de jeu et à personnaliser le contenu que nous vous proposons.</ListItem>
                <ListItem><strong>Données de localisation:</strong> Nous utilisons les services de localisation de votre appareil pour vous proposer des chasses au trésor à proximité et pour valider vos réponses aux énigmes.</ListItem>
                <ListItem><strong>Données de caméra:</strong> L'application utilise votre caméra pour scanner des codes QR et valider vos réponses aux énigmes. Nous ne stockons pas les images capturées par votre caméra.</ListItem>
            </List>
            <SubTitle>2. Utilisation de vos informations</SubTitle>
            <Text>Nous utilisons vos informations pour :</Text>
            <List>
                <ListItem>Vous fournir les services de X'Plore et personnaliser votre expérience de jeu.</ListItem>
                <ListItem>Vous envoyer des notifications et des communications relatives au jeu.</ListItem>
                <ListItem>Organiser des concours et des événements.</ListItem>
                <ListItem>Analyser l'utilisation de notre application, comprendre comment nous pouvons l'améliorer et développer de nouvelles fonctionnalités.</ListItem>
            </List>
            <SubTitle>3. Partage de vos informations</SubTitle>
            <Text>Nous partageons vos informations avec :</Text>
            <List>
                <ListItem>Les fournisseurs de services tiers qui nous aident à exploiter notre application et à vous fournir nos services (par exemple, Firebase pour le stockage de données).</ListItem>
                <ListItem>Les partenaires avec lesquels nous organisons des concours ou des événements.</ListItem>
                <ListItem>Les autorités compétentes si la loi l'exige.</ListItem>
            </List>
            <SubTitle>4. Stockage de vos informations</SubTitle>
            <Text>
                Nous stockons vos informations sur des serveurs sécurisés situés en Europe. Nous prenons des mesures raisonnables pour protéger vos informations contre tout accès non autorisé, toute divulgation, toute altération ou toute destruction.
            </Text>
            <SubTitle>5. Vos droits</SubTitle>
            <Text>Vous avez le droit de :</Text>
            <List>
                <ListItem>Accéder à vos informations personnelles.</ListItem>
                <ListItem>Rectifier vos informations personnelles si elles sont inexactes ou incomplètes.</ListItem>
                <ListItem>Supprimer vos informations personnelles.</ListItem>
                <ListItem>Vous opposer au traitement de vos informations personnelles.</ListItem>
                <ListItem>Limiter le traitement de vos informations personnelles.</ListItem>
                <ListItem>Demander la portabilité de vos informations personnelles.</ListItem>
            </List>
            <Text>
                Pour exercer vos droits, veuillez nous contacter à l'adresse e-mail suivante : contact@xplore-app.com.
            </Text>
            <SubTitle>6. Modifications de cette politique de confidentialité</SubTitle>
            <Text>
                Nous pouvons mettre à jour cette politique de confidentialité. Nous vous informerons de tout changement important en publiant la nouvelle politique de confidentialité sur notre site web.
            </Text>
        </Container>
    );
}

export default PrivacyPolicy;
