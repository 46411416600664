import React, { useState } from 'react';
import styled from 'styled-components';
import emailjs from 'emailjs-com';

const Container = styled.div`
  background-color: #1f2b3a;
  color: white;
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
  margin-bottom: 50px;  /* Ajouter un espace en bas */
  border-radius: 8px;
`;

const Title = styled.h1`
  text-align: center;
  margin-bottom: 20px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const Label = styled.label`
  font-size: 1.2rem;
`;

const Input = styled.input`
  padding: 10px;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
`;

const Textarea = styled.textarea`
  padding: 10px;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
`;

const Button = styled.button`
  background-color: #19a544;
  color: white;
  padding: 10px 20px;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: #128f3a;
  }
`;

const DeleteAccount = () => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();

        const templateParams = {
            user_email: email,
            message: message,
        };

        emailjs.send('your_service_id', 'your_template_id', templateParams, 'your_user_id')
            .then((response) => {
                console.log('SUCCESS!', response.status, response.text);
                alert('Votre demande de suppression de compte a été envoyée.');
            }, (err) => {
                console.log('FAILED...', err);
                alert('Une erreur s\'est produite. Veuillez réessayer plus tard.');
            });

        setEmail('');
        setMessage('');
    };

    return (
        <Container>
            <Title>Demande de suppression de compte</Title>
            <Form onSubmit={handleSubmit}>
                <Label htmlFor="email">Email:</Label>
                <Input
                    type="email"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                />
                <Label htmlFor="message">Message:</Label>
                <Textarea
                    id="message"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    rows="5"
                    required
                />
                <Button type="submit">Demander la suppression</Button>
            </Form>
        </Container>
    );
};

export default DeleteAccount;
